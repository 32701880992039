import React from 'react'

import img_beveiliging from '../../assets/badges/beveiliging.png'
import img_evenementenbeveiliging from '../../assets/badges/evenementenbeveiliging.png'
import img_horecabeveiliging from '../../assets/badges/horecabeveiliging.png'

import img_cbo from '../../assets/badges/cbo.jpg'
import img_nen from '../../assets/badges/nen.jpg'
import img_nvb from '../../assets/badges/nvb.jpg'
import img_tuv from '../../assets/badges/tuv.jpg'
import img_vvem from '../../assets/badges/vvem.jpg'

import styles from './Footer.module.scss'

const Badges = () => (
  <div className={styles.badges}>
    <h3 className="t-heading-sub">Onze keurmerken</h3>

    {/* prettier-ignore */}
    <div className={styles.badgeContainer}>
      <div className={styles.keurmerken}>
        <img src={img_beveiliging} alt="keurmerk beveiliging" />
        <img src={img_evenementenbeveiliging} alt="keurmerk evenementen beveiliging" />
        <img src={img_horecabeveiliging} alt="keurmerk horeca beveiliging" />
      </div>

      <div className={styles.certificaten}>
        <img src={img_tuv} alt="ISO certificaten" />
        <img src={img_vvem} alt="vereniging van evenementen makers" />
        <img src={img_nvb} alt="nederlandse veiligheidsbranche" />
        <img src={img_nen} alt="nen iso 26000" />
        <img src={img_cbo} alt="centraal beveiligings orgaan" />
      </div>
    </div>
  </div>
)

export default Badges
