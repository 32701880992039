import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import styles from './Button.module.scss'

const Button = ({
  children,
  color,
  disabled,
  inline,
  onClick,
  size,
  target,
  to,
  type,
}) => {
  const classesList = [
    styles.button,
    styles[size],
    styles[color],
    inline ? styles.inline : null,
    disabled ? styles.disabled : null,
  ].join(' ')

  switch (type) {
    case 'anchor':
      return (
        <a
          className={classesList}
          href={to}
          onClick={onClick}
          rel={target === '_blank' ? 'nofollow noopener noreferrer' : undefined}
          target={target}
        >
          {children}
        </a>
      )

    case 'link':
      return (
        <Link className={classesList} onClick={onClick} to={to}>
          {children}
        </Link>
      )

    default:
      return (
        <button
          className={classesList}
          disabled={disabled}
          onClick={onClick}
          type={type}
        >
          {children}
        </button>
      )
  }
}

Button.propTypes = {
  color: PropTypes.oneOf(['cancel', 'confirm', 'default', 'text', 'white']),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'regular', 'large']),
  target: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.oneOf(['anchor', 'button', 'link', 'reset', 'submit']),
}

Button.defaultProps = {
  color: 'default',
  disabled: false,
  size: 'regular',
  type: 'button',
}

export default Button
