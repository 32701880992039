import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import styles from './Item.module.scss'

const Item = ({ path, title, nested }) => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    if (isPartiallyCurrent) {
      return { className: [styles.item, styles.itemActive].join(' ') }
    }
  }

  return (
    <div className={styles.wrapper}>
      <Link
        to={path}
        className={styles.item}
        activeClassName={styles.itemActive}
        getProps={path !== '/' ? isPartiallyActive : undefined}
      >
        <span className={styles.title}>{title}</span>
        {nested && nested.length > 0 && <span className={styles.caret}>▼</span>}
      </Link>

      {nested && nested.length > 0 && (
        <div className={styles.children}>
          {nested.map((child) => (
            <Link
              key={child.path}
              to={child.path}
              className={styles.child}
              activeClassName={styles.childActive}
            >
              {child.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  nested: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}

export default Item
