import React from 'react'

import paths from './paths'

const Icon = (props) => {
  const styles = {
    fill: 'currentColor',
    lineHeight: 'inherit',
    stroke: 'currentColor',
    strokeWidth: 0,
    verticalAlign: props.valign,
    ...props.styles,
  }

  return (
    <svg
      height={props.size}
      width={props.size}
      style={styles}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d={paths[props.name]} />
    </svg>
  )
}

Icon.defaultProps = {
  size: 16,
  valign: 'middle',
}

export default Icon
