import React from 'react'
import PropTypes from 'prop-types'

import styles from './Footer.module.scss'

const Column = ({ children, title }) => (
  <div className={styles.column}>
    <h4 className="t-heading-sub">{title}</h4>
    {children}
  </div>
)

Column.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Column
