import React from 'react'
import { Link } from 'gatsby'

import GlobalContext from '../../utils/globalContext'
import Icon from '../Icon'
import Item from './Item'

import img_logo from '../../assets/logo.png'

import styles from './Navigation.module.scss'

class Navigation extends React.Component {
  static contextType = GlobalContext

  state = {
    navOpen: false,
  }

  _toggleNavigation = (newState) => {
    this.setState({
      navOpen: newState || !this.state.navOpen,
    })
  }

  render() {
    const { navOpen } = this.state
    const { i18n } = this.context

    const classNames = {
      navigation: [styles.navigation, navOpen ? styles.navOpen : null].join(
        ' '
      ),
    }

    return (
      <div className={classNames.navigation}>
        <div className={styles.infoBar}>
          <Link to="/" className={styles.logo}>
            <img src={img_logo} alt="logo" />
          </Link>

          <div className={styles.phone}>
            <span className={styles.phoneIcon}>
              <Icon name="phone" valign="middle" />
            </span>

            <a href={`tel:${i18n.misc.phoneHtml}`} className={styles.phoneText}>
              {i18n.misc.phoneHuman}
            </a>
          </div>

          <button
            className={styles.toggle}
            onClick={() => this._toggleNavigation()}
          >
            <Icon name={this.state.navOpen ? 'close' : 'nav'} size={32} />
          </button>
        </div>

        <nav className={styles.menu}>
          {i18n.navigation.map((item) => (
            <Item
              key={item.title}
              path={item.path}
              title={item.title}
              nested={item.children}
            />
          ))}
        </nav>
      </div>
    )
  }
}

Navigation.propTypes = {}

Navigation.defaultProps = {}

export default Navigation
