export default {
  meta: {
    description: 'Beveiligingsbedrijf uit Uden. Beveiliging van bedrijven, evenementen & horeca. Landelijk inzetbaar. 3 keurmerken, 3 ISO certificaten en OHSAS 18001.',
    twitter: '@WRbeveiliging',
  },

  misc: {
    clientName: 'W&R Beveiliging',
    address: 'Oostwijk 7\n5406 XT Uden',
    addressMap: 'https://goo.gl/maps/gs7iQvySUtP2',
    email: 'info@wenrbeveiliging.nl',
    phoneHtml: '+31857440739',
    phoneHuman: '085 - 744 07 39',
  },

  navigation: [
    { title: 'Home', path: '/' },
    {
      title: 'Branches',
      path: '/branches/',
      children: [
        { title: 'Gezondheidszorg', path: '/branches/gezondheidszorg/' },
        { title: 'Horeca', path: '/branches/horeca/' },
        { title: 'Industrie & Logistiek', path: '/branches/industrielogistiek/' },
        { title: 'Onderwijs', path: '/branches/onderwijs/' },
        { title: 'Overheidsinstellingen', path: '/branches/overheidsinstellingen/' },
        { title: 'Bouw', path: '/branches/bouw/' },
      ]
    },
    {
      title: 'Diensten',
      path: '/diensten/',
      children: [
        { title: 'Evenementenbeveiliging', path: '/diensten/evenementenbeveiliging/' },
        { title: 'Horecabeveiliging', path: '/diensten/horecabeveiliging/' },
        { title: 'Objectbeveiliging', path: '/diensten/objectbeveiliging/' },
        { title: 'Portofoonverhuur', path: '/diensten/portofoonverhuur/' },
        { title: 'Transportbeveiliging', path: '/diensten/transportbeveiliging/' },
        { title: 'Verkeersregelaars', path: '/diensten/verkeersregelaars/' },
      ]
    },
    { title: 'Nieuws', path: '/nieuws/' },
    { title: 'Contact', path: '/contact/' },
  ],

  footer: {
    navigation: [
      { title: 'Over ons', path: '/over-ons/' },
      { title: 'Keurmerken en Certificeringen', path: '/keurmerken-certificeringen/' },
      { title: 'Vacatures', path: '/vacatures/' },
      { title: 'Sitemap', path: '/sitemap/' },
      { title: 'Privacy', path: '/privacy/' },
      { title: 'Leveringsvoorwaarden', path: '/leveringsvoorwaarden/' },
    ],
    quicknav: [
      { title: 'Beveiligingsbedrijf', path: '/beveiligingsbedrijf/' },
      { title: 'Beveiligingsdienst', path: '/beveiligingsdienst/' },
      { title: 'Bewakingsbedrijf', path: '/bewakingsbedrijf/' },
      { title: 'Bewakingsdienst', path: '/bewakingsdienst/' },
      { title: 'Bouwplaats beveiliging', path: '/bouwplaats-beveiliging/' },
      { title: 'Pandbeveiliging', path: '/pandbeveiliging/' },
      { title: 'Portofoon verhuur', path: '/portofoon-verhuur/' },
      { title: 'Securitybedrijf', path: '/securitybedrijf/' },
      { title: 'Terrein beveiligen', path: '/terrein-beveiligen/' },
      { title: 'Tijdelijke camerabeveiliging', path: '/tijdelijke-camerabeveiliging/' },
    ],
    diensten: [
      { title: 'Evenementenbeveiliging', path: '/diensten/evenementenbeveiliging/' },
      { title: 'Horecabeveiliging', path: '/diensten/horecabeveiliging/' },
      { title: 'Objectbeveiliging', path: '/diensten/objectbeveiliging/' },
      { title: 'Portofoonverhuur', path: '/diensten/portofoonverhuur/' },
      { title: 'Transportbeveiliging', path: '/diensten/transportbeveiliging/' },
      { title: 'Verkeersregelaars', path: '/diensten/verkeersregelaars/' },
    ],
  },

  social: [
    { title: 'Facebook', url: 'http://www.facebook.com/pages/WR-beveiligingWR-support-services/381806771848271', icon: 'socialFacebook' },
    { title: 'Twitter', url: 'https://www.twitter.com/wrbeveiliging', icon: 'socialTwitter' },
    { title: 'LinkedIn', url: 'https://www.linkedin.com/company/wr-beveiliging', icon: 'socialLinkedin' },
    { title: 'YouTube', url: 'https://www.youtube.com/channel/UCd84ZobD2Y3t7xXS4LPvMuQ', icon: 'socialYoutube' },
  ],

  testimonials: [
    {
      id: 1,
      excerpt: 'Ordentelijk en veilig',
      text: 'Het TROS Muziekfeest in Uden is een groot succes geweest. Een snikhete avond en onverwachts 8.000 bezoekers, terwijl er 4.000 werden verwacht. Mede dankzij de inzet van W&R beveiliging is het ook ordentelijk en veilig verlopen.',
      authorName: 'Pascal Donkers',
      authorFunction: 'Organisator - TROS Muziekfeest op het Plein in Uden',
    },
    {
      id: 2,
      excerpt: 'Het visitekaartje van ons bedrijf',
      text: 'Als ontwikkelaar van software staat veiligheid boven alles. Iedereen die ons bedrijf bezoekt moet zich eerst melden bij de beveiliger die de bezoeker registreert en bezoekers op de hoogte brengt van het bedrijfsreglement. De beveiliger is het visitekaartje van ons bedrijf en kwaliteit staat bij ons hoog in het vaandel. W&R bezit het keurmerk beveiliging en iso 9001 certificaat en de medewerkers verstaan hun vak, dit zijn de redenen waarom wij al jaren samenwerken.',
      authorName: 'Dhr. Van Maanen',
      authorFunction: 'Facilitair Manager - Dyochem',
    },
    {
      id: 3,
      excerpt: 'Zeer correct, keurig verzorgd en netjes op tijd',
      text: 'Voor wat betreft de dienstverlening van W&R beveiliging kan ik niet anders dan positief zijn. De première is rustig verlopen en zonder problemen. De ingezette beveiliger was zeer correct, keurig verzorgd en netjes op tijd. Kortom prima dienstverlening waar ik in de toekomst, graag nogmaals gebruik van maak.',
      authorName: 'Yvonne de Man',
      authorFunction: 'Vestigingsmanager - Bioscoop Take Ten Uden',
    },
    {
      id: 4,
      excerpt: 'Al jaren met volle tevredenheid',
      text: 'Wij maken al jaren met volle tevredenheid gebruik van de diensten van W&R beveiliging. Iedere nacht wordt onze receptie bemand door een beveiliger die de patiënten ontvangt en indien nodig de verpleegkundige ondersteunt. Een vereiste is dat de beveiligers in het bezit zijn van een BHV certificaat.',
      authorName: 'Mevr. Broekhuizen-Daandels',
      authorFunction: 'Praktijkmanager - Huisartsenpost',
    },
    {
      id: 5,
      excerpt: 'Het grootste winterevenement in Uden en omgeving',
      text: 'W&R beveiliging verzorgt al 5 jaar de beveiliging van het grootste winterevenement in Uden en omgeving, Uden on Ice. W&R beveiliging draagt 30 dagen de zorg voor de beveiliging van het terrein en de toegangscontrole in de avonden voor de horeca. Wij, van de Uden on Ice organisatie, ervaren dit als een prettige samenwerking waarbij flexibiliteit, accuraat en professionaliteit belangrijke kernwaarden zijn. De onderlinge communicatie verloopt heel soepel, mede door de ‘korte lijntjes’ met Sander en Willem.',
      authorName: 'Jean-Louis van der Veen',
      authorFunction: 'Organisator - Uden on Ice',
    },
    {
      id: 6,
      excerpt: 'Wensen vanuit ons worden altijd in acht genomen',
      text: 'W&R is voor ons de beste keuze gebleken door het persoonlijke maar altijd professionele contact wat we onderhielden. Wensen vanuit ons als organisatie worden altijd in acht genomen. Dit biedt ons de mogelijkheid om, in samenwerking met W&R, een positief, goed georganiseerd en bovenal veilig evenement neer te zetten.',
      authorName: 'Willem van Diesen',
      authorFunction: 'Organisatie - Stg. Zomerfeesten Deurne',
    },
    {
      id: 7,
      excerpt: 'Betrouwbaar, vriendelijk en gastheerschap',
      text: 'Heel erg tevreden over W&R Beveiliging!!! Café Sgt. Peppers Uden werkt al vele jaren met W&R Beveiliging. Elk weekend tijdens de uitgaansavonden, maar ook met de evenementen die Sgt. Peppers organiseert. Betrouwbaar, vriendelijk en gastheerschap, dat zijn voorwaarden van Sgt. Peppers en daar sluit W&R Beveiliging helemaal bij aan.',
      authorName: 'Robin van Soest',
      authorFunction: 'Eigenaar - Sgt. Peppers Uden',
    },
    {
      id: 8,
      excerpt: 'Een belangrijke schakel in ons bedrijfsproces',
      text: 'Op onze scheepswerf is veiligheid een hot item, wij zijn dan ook VCA gecertificeerd. De beveiligers van W&R beveiliging zijn een belangrijke schakel in ons bedrijfsproces, zij hebben als taak te controleren dat iedereen de veiligheidsvoorschriften in acht neemt. Binnenkomende en vertrekkende bezoekers, vrachtverkeer en personeel wordt in- en uitgeschreven door de beveiliging.',
      authorName: 'Dhr. Driessen',
      authorFunction: 'Facilitair Manager - Scheepswerf Heusden',
    },
  ],

  index: {
    welcome: 'Welkom!',
    hero: [
      {
        title: 'Gezondheidszorg',
        icon: 'suitcase',
        ctaText: 'Meer info',
        ctaPath: '/branches/gezondheidszorg/',
        description: 'Beveiliging van zorginstellingen & ziekenhuizen.' 
      },
      {
        title: 'Industrie & Logistiek',
        icon: 'tools',
        ctaText: 'Meer info',
        ctaPath: '/branches/industrielogistiek/',
        description: 'Beveiliging van bedrijven in logistiek & industrie.' 
      },
      {
        title: 'Onderwijs',
        icon: 'graduation',
        ctaText: 'Meer info',
        ctaPath: '/branches/onderwijs/',
        description: 'Beveiliging van basisschool tot universiteit.' 
      },
      {
        title: 'Overheidsinstellingen',
        icon: 'temple',
        ctaText: 'Meer info',
        ctaPath: '/branches/overheidsinstellingen/',
        description: 'Beveiliging van de publieke sector in Nederland.' 
      },
      {
        title: 'Bouw',
        icon: 'robot',
        ctaText: 'Meer info',
        ctaPath: '/branches/bouw/',
        description: 'Voorkom schade & diefstal door bouwplaatsbeveiliging.' 
      },
      {
        title: 'Horeca',
        icon: 'dinner',
        ctaText: 'Meer info',
        ctaPath: '/branches/horeca/',
        description: 'Horecabeveiliging door onze gastheren- en gastdames.' 
      },
      {
        title: 'Portofoon verhuur',
        special: true,
        icon: 'walkietalkie',
        ctaText: 'Direct bestellen',
        ctaPath: '/aanvraagformulier/',
        ctaAltText: 'Meer info en tarieven',
        ctaAltPath: '/diensten/portofoonverhuur/',
        description: 'Wij verhuren professionele portofoons aan bedrijven, instellingen en overheidsinstanties in Nederland.' 
      },
    ],
  },

  services: {
    title: 'Services',
  },
}
