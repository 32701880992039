import React from 'react'

import localization from './localization'

import styles from './CookieConsent.module.scss'

class CookieConsent extends React.Component {
  state = {
    consent: true,
  }

  componentDidMount = () => {
    if (!this.props.cookies.get('COOKIECONSENT')) {
      this.setState({ consent: false })
    }
  }

  _consent = () => {
    this.setState({ consent: true })

    const date = new Date()
    date.setFullYear(date.getFullYear() + 10)

    this.props.cookies.set('COOKIECONSENT', 'true', {
      path: '/',
      expires: date,
    })
  }

  render() {
    if (!this.state.consent) {
      return (
        <button
          className={[
            styles.cookieConsent,
            this.state.consent ? styles.consented : null,
          ].join(' ')}
          onClick={(e) => this._consent(e)}
        >
          <span className={styles.innerText}>
            {localization[this.props.language]}
          </span>

          <div className={styles.innerButton}>×</div>
        </button>
      )
    }

    return null
  }
}

export default CookieConsent
