import React from 'react'
import Helmet from 'react-helmet'
import { graphql, navigate, StaticQuery } from 'gatsby'
import { Location } from '@reach/router'

import CookieConsent from '../components/CookieConsent'
import Footer from '../components/Footer'
import GlobalContext from '../utils/globalContext'
import Navigation from '../components/Navigation'

import config from '../config'
import cookies from '../utils/cookies'
import i18n from '../i18n'

import '../styles/style.scss'

import img_opengraph from '../assets/opengraph.jpg'

const staticQuery = graphql`
  query {
    pages: allSitePage {
      edges {
        node {
          context {
            slug
            language
          }
        }
      }
    }
  }
`

const pages = []

class Root extends React.Component {
  state = {
    language: cookies.get('LANGUAGE') || config.defaultLanguage,
    pages: [],
  }

  _populatePages = (queryPages) => {
    queryPages.edges.forEach(({ node }) => {
      if (node.context) {
        pages.push({
          path: node.context.slug,
          language: node.context.language,
        })
      }
    })
  }

  _switchLanguage = (newLanguage) => {
    // Save previous language to variable before changing it
    const prevLanguage = this.state.language

    // Update state and context
    this.setState({ language: newLanguage })

    // Save to cookie
    const date = new Date()
    date.setFullYear(date.getFullYear() + 10)

    cookies.set('LANGUAGE', newLanguage, {
      path: '/',
      expires: date,
    })

    // Navigate
    const { pathname } = this.props.location
    let newPathname

    // Construct new path
    if (newLanguage === config.defaultLanguage) {
      // Remove path language if newLanguage is the default language
      newPathname = pathname.replace(`/${prevLanguage}/`, '/')
    } else if (prevLanguage === config.defaultLanguage) {
      // Add path language if current page language is the default language
      newPathname = `/${newLanguage + pathname}`
    } else {
      // Replace path language if current page language is not the default
      newPathname = pathname.replace(`/${prevLanguage}/`, `/${newLanguage}/`)
    }

    // Navigate only if page exists
    if (pages.findIndex((page) => page.path === newPathname) > -1) {
      navigate(newPathname, { replace: true })
    }

    // Navigate only if page exists with added trailing slash
    if (pages.findIndex((page) => page.path === `${newPathname}/`) > -1) {
      navigate(`${newPathname}/`, { replace: true })
    }
  }

  render() {
    const context = {
      i18n: i18n[this.state.language] || i18n[config.defaultLanguage],
      language: this.state.language,
      switchLanguage: this._switchLanguage,
      urlPathname: this.props.location.pathname,
    }

    return (
      <StaticQuery
        query={staticQuery}
        render={(data) => {
          // Populate pages array once per session
          if (pages.length === 0) {
            this._populatePages(data.pages)
          }

          return (
            <>
              {/* prettier-ignore */}
              <Helmet>
                <title>{config.title}</title>
                <meta name="description" content={context.i18n.meta.description} />
                {context.i18n.meta.keywords && <meta name="keywords" content={context.i18n.meta.keywords} />}
                <meta property="og:title" content={config.title} />
                <meta property="og:description" content={context.i18n.meta.description} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content={context.language} />
                <meta property="og:image" content={config.siteUrl + img_opengraph} />
                <meta property="og:image:height" content="1200" />
                <meta property="og:image:width" content="1200" />
                {context.i18n.meta.twitter && <meta property="twitter:site" content={context.i18n.meta.twitter} />}
              </Helmet>

              <CookieConsent cookies={cookies} language={this.state.language} />

              <GlobalContext.Provider value={context}>
                <Navigation />
                {this.props.children}
                <Footer />
              </GlobalContext.Provider>
            </>
          )
        }}
      />
    )
  }
}

export default (props) => (
  <Location>
    {(locationProps) => <Root {...locationProps} {...props} />}
  </Location>
)
