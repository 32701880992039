import React from 'react'
import { Link, navigate } from 'gatsby'

import Badges from './Badges'
import Column from './Column'
import GlobalContext from '../../utils/globalContext'
import Icon from '../../components/Icon'

import styles from './Footer.module.scss'

const Footer = () => {
  const handleQuicknavChange = (e) => {
    navigate(e.target.value)
  }

  return (
    <GlobalContext.Consumer>
      {({ i18n }) => (
        <>
          <Badges />

          <footer className={styles.footer}>
            <div className={styles.wrapper}>
              <Column title="W&R Beveiliging">
                {i18n.footer.navigation.map((item) => (
                  <Link
                    key={item.title}
                    to={item.path}
                    className={styles.footerLink}
                  >
                    {item.title}
                  </Link>
                ))}

                <select
                  className={styles.quicknav}
                  onChange={handleQuicknavChange}
                >
                  <option>Snelmenu</option>
                  {i18n.footer.quicknav.map((item) => (
                    <option key={item.title} value={item.path}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </Column>

              <Column title="Diensten">
                {i18n.footer.diensten.map((item) => (
                  <Link
                    className={styles.footerLink}
                    key={item.title}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                ))}
              </Column>

              <Column title="Social Media">
                {i18n.social.map((item) => (
                  <a
                    key={item.title}
                    href={item.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className={styles.footerSocial}
                  >
                    <Icon name={item.icon} size={16} />
                  </a>
                ))}
              </Column>

              <Column title="Contact">
                <div className={styles.clientAddress}>
                  <div className="t-bold">{i18n.misc.clientName}</div>
                  {i18n.misc.address}
                </div>

                <div className={styles.clientContact}>
                  <a
                    href={`tel:${i18n.misc.phoneHtml}`}
                    className={styles.clientPhone}
                  >
                    {i18n.misc.phoneHuman}
                  </a>

                  <a
                    href={`mailto:${i18n.misc.email}`}
                    className={styles.clientEmail}
                  >
                    {i18n.misc.email}
                  </a>
                </div>
              </Column>
            </div>
          </footer>
        </>
      )}
    </GlobalContext.Consumer>
  )
}

export default Footer
