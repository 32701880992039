import React from 'react'

import config from '../config'
import i18n from '../i18n'

const GlobalContext = React.createContext({
  language: config.defaultLanguage,
  switchLanguage: () => {},
  i18n: i18n[config.defaultLanguage],
})

export default GlobalContext
